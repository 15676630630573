
import {defineComponent} from 'vue'
import {Breadcrumb, BreadcrumbItem, Card, Content, Icon, Layout, MenuItem, Menu} from "view-ui-plus";

export default defineComponent({
  name: 'App',
  components: {Icon, MenuItem, BreadcrumbItem, Breadcrumb, Card, Content, Layout, Menu},
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout'
    },
  }
})
