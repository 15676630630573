
import {defineComponent} from 'vue'
import {Breadcrumb, BreadcrumbItem, Card, Content, Icon, Layout, MenuItem, Menu} from "view-ui-plus";
import TopMenu from "@/layout/TopMenu.vue";

export default defineComponent({
  name: 'DefaultLayout',
  components: {TopMenu, Icon, MenuItem, BreadcrumbItem, Breadcrumb, Card, Content, Layout, Menu},
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    }
  }
})
