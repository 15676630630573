import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {loadFonts} from './plugins/webfontloader'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import './theme/index.less'
import {createPinia} from 'pinia'

loadFonts()

const pinia = createPinia()

import DefaultLayout from './layout/DefaultLayout.vue'
import NodeLayout from './layout/NodeLayout.vue'


const app = createApp(App)
    .component('default-layout', DefaultLayout)
    .component('node-layout', NodeLayout)
    .use(router)
    .use(store)
    .use(pinia)
    // .use(vuetify)
    .use(ViewUIPlus)
    .mount('#app')

