<template>
  <Menu mode="horizontal" theme="dark" :active-name="route.name">
    <div class="layout-nav">
      <MenuItem name="node-list" to="/node-list">
        <Icon type="ios-navigate"></Icon>
        Nodes
      </MenuItem>
      <MenuItem name="etcd" to="/etcd">
        <Icon type="ios-keypad"></Icon>
        Etcd
      </MenuItem>
      <MenuItem name="3">
        <Icon type="ios-analytics"></Icon>
        TODO
      </MenuItem>
      <MenuItem name="4">
        <Icon type="ios-paper"></Icon>
        SETTINGS
      </MenuItem>
    </div>
  </Menu>

</template>

<script setup type="ts">
import {Icon, MenuItem, Menu} from "view-ui-plus";
import {useRoute} from "vue-router";

const route = useRoute()
</script>

<style scoped>

.layout-nav {
  margin: 0 20px 0 auto;
  display: flex;
  justify-content: flex-end;
}

</style>
